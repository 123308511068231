@font-face {
  font-family: 'Soleil Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Soleil Regular'),
    url('/assets/fonts/soleil/SoleilRegular.woff') format('woff');
}

@font-face {
  font-family: 'Soleil Book';
  font-style: normal;
  font-weight: normal;
  src: local('Soleil Book'), url('/assets/fonts/soleil/SoleilBook.woff') format('woff');
}

@font-face {
  font-family: 'Soleil Light';
  font-style: normal;
  font-weight: normal;
  src: local('Soleil Light'), url('/assets/fonts/soleil/SoleilLight.woff') format('woff');
}

@font-face {
  font-family: 'Soleil Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Soleil Bold'), url('/assets/fonts/soleil/SoleilBold.woff') format('woff');
}
