@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,500,500i,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;600;700;900&display=swap');

@import '~@angular/cdk/overlay-prebuilt.css';

// /* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import './theme/variables';
@import './assets/fonts/soleil/style.css';

// /* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";
// @import "~@ionic/angular/css/display.css";

/* You can add global styles to this file, and also import other style files */

body {
  font-family: Roboto, sans-serif;
  --primary-color: #d9a57d;
  --text-color-light: #9ca4a8;
  --text-color: #283136;
  --max-side-pane-width: 278px;

  --primary-950: #441906;
  --primary-900: #7e3610;
  --primary-800: #9c4011;
  --primary-700: #c55108;
  --primary-600: #ed6d07;
  --primary-500: #fc8712;
  --primary-400: #fea438;
  --primary-300: #ffbc57;
  --primary-200: #ffdfaa;
  --primary-100: #fff0d4;
  --primary-50: #fff9ed;

  --secondary-950: #121212;
  --secondary-900: #222222;
  --secondary-800: #333333;
  --secondary-700: #4d4d4d;
  --secondary-600: #666666;
  --secondary-500: #808080;
  --secondary-400: #999999;
  --secondary-300: #b3b3b3;
  --secondary-200: #cccccc;
  --secondary-100: #e6e6e6;
  --secondary-50: #f6f6f6;

  --white-opacity-10: rgba(255, 255, 255, 0.1);
  --white-opacity-50: rgba(255, 255, 255, 0.5);
  --white-opacity-70: rgba(255, 255, 255, 0.7);

  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --black-opacity-50: rgba(0, 0, 0, 0.5);
  --black-opacity-70: rgba(0, 0, 0, 0.7);

  --white: #ffffff;
  --black: #000000;


  --black-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 64.5%);
  --white-gradient:  linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 59%);

  --page-background: var(--secondary-950);
  --title-text-color: var(--white);
  --description-text-color: var(--secondary-300);
  --eyebrow-text-color: var(--secondary-300);
  --highlight-text-color: var(--primary-300);
  --duration-background-color: var(--white-opacity-70);
  --duration-text-color: var(--black);
  --separation-border-color: var(--white-opacity-10);
  --menu-button-color: var(--secondary-200);
  --expander-background-color: var(--white-opacity-10);
  --expander-icon-color: var(--white);
  --button-color: var(--white);
  --button-background: var(--white-opacity-10);
  

  --modal-background: var(--secondary-900);
  --modal-close-button-color: var(--secondary-200);
  --modal-button-background-color: var(--white-opacity-10);
  --modal-button-text-color: var(--white-opacity-70);

  --footer-background-color: var(--secondary-900);
  --footer-text-color: var(--secondary-300);

  --gradient-color: var(--black-gradient);

  .light-theme {
    --page-background: var(--secondary-50);
    --title-text-color: var(--black);
    --description-text-color: var(--secondary-500);
    --eyebrow-text-color: var(--secondary-500);
    --highlight-text-color: var(--secondary-200);
    --duration-background-color: var(--white-opacity-70);
    --duration-text-color: var(--black);
    --separation-border-color: var(--black-opacity-10);
    --menu-button-color: var(--secondary-800);
    --expander-background-color: var(--white-opacity-10);
    --expander-icon-color: var(--black);
    --button-color: var(--black);
    --button-background: var(--black-opacity-10);

    --modal-background: var(--white);
    --modal-close-button-color: var(--secondary-800);
    --modal-button-background-color: var(--black-opacity-10);
    --modal-button-text-color: var(--black-opacity-70);

    --footer-background-color: var(--white);
    --footer-text-color: var(--secondary-500);

    --gradient-color: var(--white-gradient);
  }

  app-input {
    --input-label-color: var(--text-color-light) !important;
    --input-color: var(--text-color) !important;
    --input-focus-color: var(--primary-color) !important;
  }

  app-select {
    --select-color: var(--text-color) !important;
  }

  ion-header ion-toolbar ion-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }

  ion-header ion-toolbar {
    app-icon {
      --icon-size: 20px;
    }
  }

  .swiper-slide {
    -webkit-backface-visibility: hidden !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }

  .link {
    color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
    cursor: pointer;
  }

  .alert-backdrop,
  .modal-backdrop {
    background-color: #000000;
    opacity: 0.8 !important;
  }

  .dark-modal-backdrop {
    background-color: #000000;
    opacity: 1 !important;
  }
}

app-root {
  display: block;
}

button.alert-button.secondary {
  color: #ff9393 !important;
}

ion-menu-button {
  --color: #d9a57d;
}

:host {
  --spinner-thickness: 5px;
  --spinner-size: 10em;
  --spinner-speed: 0.8s;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s cubic-bezier(0, 0, 0.2, 1);
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: var(--spinner-size);
  height: var(--spinner-size);
}

// app-overlay {
//   margin-bottom: constant(safe-area-inset-top);
//   margin-bottom: env(safe-area-inset-top);
// }

app-product-card .description {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li:first-child {
      padding-top: 0px;
    }
    li {
      padding: 10px 0;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: var(--spinner-thickness) solid rgba(255, 255, 255, 0.2);
  border-right: var(--spinner-thickness) solid rgba(255, 255, 255, 0.2);
  border-bottom: var(--spinner-thickness) solid rgba(255, 255, 255, 0.2);
  border-left: var(--spinner-thickness) solid #ffffff;
  transform: translateZ(0);
  animation: load8 var(--spinner-speed) infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

ion-toast {
  --button-color: #d4af37;
}

ion-toast.video-access {
  --button-color: var(--primary-color);
  --ion-font-family: 'Montserrat';
}

ion-toast.video-access.md {
  --color: rgba(255, 255, 255, 0.58);
  --background: #32373a;
}

// center toast on page when side pane is visible
@media (min-width: 992px) {
  ion-toast {
    --start: var(--max-side-pane-width);
  }
}

ion-split-pane {
  --side-max-width: var(--max-side-pane-width);
  --border: solid 0.1px #0000000d;
}

.modal-panel {
  overflow: auto;

  app-overlay {
    box-shadow: none;
  }

  app-overlay,
  app-tile {
    border-radius: 10px 10px 0 0 !important;
  }

  &.embedded {
    app-tile {
      app-tile-title {
        --tile-title-margin-bottom: 0;
      }
    }

    @media screen and (max-width: $mobile-screen-size) {
      max-width: 300px !important;

      app-tile {
        --wrapper-padding: 25px 25px 22px 25px;
      }
    }

    app-overlay {
      border-radius: 10px !important;
    }
  }
}

.ion-page {
  width: 100%;
  height: 100%;
}

app-icon.button-left-aligned {
  --icon-position: absolute;
  --icon-left: 20px;
  --icon-top: 50%;
  --icon-transform: translate(0, -50%);
  --size: 18px;
}

@media screen and (max-width: $mobile-screen-size) {
  .modal-panel:not(.embedded) {
    bottom: 0;
    left: 0;
    position: absolute !important;

    app-overlay,
    app-tile {
      border-radius: 20px 20px 0 0 !important;
    }
  }
}

@media screen and (max-width: $mobile-min-screen-size) {
  app-icon {
    &.button-left-aligned {
      --icon-left: 15px;
    }
  }
}

.backdrop-no-scroll {
  .cdk-overlay-container {
    z-index: -1;
  }
}

.options-video-popover {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 240px;
    transform: translateY(-5px);
    border-radius: 1px;
    background: transparent;

    .host-wrapper {
      display: flex;
      height: 343px;
      margin: 10px 0;

      .wrapper {
        visibility: hidden;

        // In Chrome when I set the switch-options-direction class to host-wrapper,
        // The wrapper block is blinking from top to bottom so to fix we can animate the wrapper
        transition: visibility 0.1s linear;
      }

      &.switch-options-direction {
        flex-flow: column-reverse;

        .wrapper {
          visibility: visible;
        }
      }

      &.regular-options-direction {
        flex-flow: column;

        .wrapper {
          visibility: visible;
        }
      }
    }
  }

  &.mobile {
    &.fullscreen {
      .popover-content {
        .host-wrapper {
          max-height: 200px;
          overflow: auto;
          flex-flow: column;
        }
      }
    }

    .popover-content {
      .host-wrapper {
        height: auto;
      }
    }
  }
}

.options-popover {
  .wrapper {
    width: 240px !important;
    border-radius: 0 !important;
    height: auto !important;
  }
}

.remove-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
  }
}

.darken-on-hover {
  &::after {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: none;
    transition: background 0.15s ease-in-out;
    pointer-events: none;
    z-index: 1;
  }

  &:hover::after {
    background: rgba(0, 0, 0, 0.2);
  }
}

.player-description-modal {
  --header: 56px;
  --player-height: 100vw * 9 / 16;
  --border-radius: 20px 20px 0 0;
  --height: calc(100% - var(--header) - var(--player-height));
  align-items: flex-end;
}

.three-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.multi-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: var(--number-of-lines, 5);
  }
}

* {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
